//utils
import './utils/plugins'
import './utils/translations'
import './utils/global-components'
//
import moment from 'moment';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHead from 'vue-head'
// moment
Vue.prototype.moment = moment

// //VueExpandableImage
// import VueExpandableImage from 'vue-expandable-image'
//
// Vue.use(VueExpandableImage)


//VueTelInput
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-tel-input/dist/css/sprite.css'; // Flags styles
import 'vue-tel-input/dist/css/component.css'; // Component styles
Vue.use(VueTelInput);


Vue.use(VueHead)
//vue configs
Vue.config.productionTip = false;

//set token after refresh page


const app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
});

Vue.initI18nManager().then(() => {
    app.$mount('#app')
});
